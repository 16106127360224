import React from 'react';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { Flex, Box } from 'components/ReflexBox';

import Page from 'components/Page';
import ContentSection from 'components/ContentSection';
import SectionIntro from 'components/SectionIntro';
import Button from 'components/Button';
import { Teaser } from 'components/Blogpost';
import { H3 } from 'components/Typo';
import { getThemeSpacePx, getThemeColor, rgba } from 'utils/css-utils';

import mainTags from 'assets/data/maintags.json';

const metaTags = {
  title: 'webkid blog',
  author: 'Webkid',
  twitterUsername: '@wbkd',
  description:
    'A blog about data visualizations, interactive maps and web development',
  siteUrl: 'https://webkid.io/blog/',
  robots: 'index,follow',
  isBlog: true,
};

const TagButton = styled(Button)`
  border-radius: 2px;
  padding: ${getThemeSpacePx(2)} ${getThemeSpacePx(2)};
  margin-right: ${getThemeSpacePx(3)};

  &:hover {
    background-color: ${(p) => rgba(getThemeColor(p.color)(p), 0.2)};
    color: ${(p) => getThemeColor(p.color)(p)};
  }
`;

const filterPublished = (post) => {
  return process.env.NODE_ENV === 'development' || post.frontmatter.published;
};

const BlogIndex = ({ data, pageContext }) => {
  const posts = data.posts.edges
    .map((edge) => edge.node)
    .filter(filterPublished);

  const recommendedPosts = posts.filter((post) => post.frontmatter.recommended);

  return (
    <Page theme="light" metaTags={metaTags}>
      <ContentSection centered>
        <SectionIntro
          css={{ textAlign: 'left', maxWidth: '550px', marginLeft: 0 }}
          title="webkid Blog"
          text="This is our space for writing tutorials and collecting things we find interesting. It is mostly about data visualizations, interactive maps and web development in general."
        />
        <Flex mb={4} flexWrap="wrap">
          {mainTags
            .filter((tag) => tag.icon)
            .map((tag) => (
              <Link
                to={
                  pageContext.activeTag === tag.id
                    ? '/blog'
                    : `/blog/tags/${tag.slug}`
                }
                key={tag.id}
              >
                <TagButton
                  type="ghost"
                  color={tag.color}
                  icon={tag.icon}
                  iconSize={18}
                  active={pageContext.activeTag === tag.id}
                >
                  {tag.id}
                </TagButton>
              </Link>
            ))}
        </Flex>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1, 2 / 3]}>
            {posts.map((post) => (
              <Teaser post={post} key={post.fields.slug} />
            ))}
          </Box>
          {recommendedPosts.length > 0 && (
            <Box width={[1, 1, 1, 1 / 4]} ml="auto">
              <H3>Recommended</H3>
              {recommendedPosts.map((post) => (
                <Teaser minimal post={post} key={post.fields.slug} />
              ))}
            </Box>
          )}
        </Flex>
      </ContentSection>
    </Page>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogListing($tags: [String]) {
    posts: allMdx(
      filter: {
        fields: { slug: { regex: "/blog/" } }
        frontmatter: { tags: { in: $tags } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            excerpt
            tags
            recommended
            published
            author {
              id
              name
              bio
              twitter
            }
          }
        }
      }
    }
  }
`;
